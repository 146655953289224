body, html {
  margin: 0;
  padding: 0;
}

button:hover {
  background-color: rgba(0, 0, 0, 0.7) !important;
  border: none;
  color: white !important;
  cursor: pointer !important;
  opacity: 0.8 !important;
}
.text {
  font-size: 80%;
  font-family: Arial, Helvetica, sans-serif;
}

.item {
  width: 50;
  height: 50;
}

.list-container::-webkit-scrollbar {
  width: 0.5rem;
  position: absolute;
  background-color: transparent;
}

.list-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.list-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.list-item {
  scroll-snap-align: start;
  cursor: pointer;
  max-height: 104;
  flex-direction: column;
}

.list-container {
  list-style-type: none;
  padding: 0;
  z-index: 11 !important;
  margin: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  border-bottom-right-radius: 10%;
  border-bottom-left-radius: 10%;
  border-top-right-radius: 10%;
  border-top-left-radius: 10%;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  backdrop-filter: blur(5px);
  max-height: 450px;
}

.list-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50px;
  height: 10px !important;
  border: 2px solid rgba(0, 0, 0, 0.3);
}

.list-container::-webkit-scrollbar-thumb:horizontal {
  width: 10px;
}

.list-container::-webkit-scrollbar-thumb:vertical {
  height: 10px;
}

.list-container::-webkit-scrollbar-thumb:horizontal:active,
.list-container::-webkit-scrollbar-thumb:vertical:active {
  background-color: rgba(0, 0, 0, 0.4);
}

.list-container::-webkit-scrollbar-thumb:horizontal:hover,
.list-container::-webkit-scrollbar-thumb:vertical:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.list-container::-webkit-scrollbar-thumb:horizontal:active:hover,
.list-container::-webkit-scrollbar-thumb:vertical:active:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.list-item {
  display: flex;
  align-items: center;
  /* margin-bottom: 10px; */
}

.list-image {
  height: 100px;
  width: 150px;
  /* margin-right: 10px; */
}

.list-item a {
  text-decoration: none;
  color: inherit;
}

.container {
  overflow: none;
  height: 100vh;
  background: radial-gradient(closest-side, transparent, transparent), linear-gradient(to top right, #0C2522, #0E2924, #25563F, #56BC82);
}

.inner-container {
  height: 100%;
  width: 100%;
  justify-content: center !important;
  overflow: hidden;
  position: relative;
}

.image {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  z-index: 10;
  transform: translate(-50%, -50%);
  width: 30%;
  border-radius: 10%;
  backdrop-filter: blur(5px);
}

.image-mobile {
  position: absolute;
  top: 50%;
  text-align: center;
  left: 50%;
  z-index: 8;
  transform: translate(-50%, -50%);
  width: 80%;
  border-radius: 15%;
  backdrop-filter: blur(5px);
}

.custom-component {
  position: absolute;
  display: flex;
  border-radius: 10px;
  backdrop-filter: blur(15px);
  align-items: center;
  justify-content: center;
}

.custom-component.column {
  flex-direction: column;
}

.custom-component.column-reverse {
  flex-direction: column-reverse;
}

.custom-component.left {
  left: calc(var(--position-x) - 80);
}

.custom-component.right {
  left: calc(var(--position-x) + 450);
}

.custom-component.top {
  top: var(--position-y);
}

.custom-component.bottom {
  top: calc(var(--position-y) - 450);
}

.custom-component.expanded {
  z-index: 12;
}

.custom-component.expanded-medium {
  z-index: 11;
}

.custom-component.expanded-small {
  z-index: 1;
}

.custom-component.transition {
  transition: all 0.5s ease;
}

.connect-text {
  width: 100%;
  font-size: 80%;
  font-family: Arial, Helvetica, sans-serif;
}

.connect-container {
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 10;
  bottom: 50px;
  color: white;
  
}

.connect-link {
  text-decoration: none;
  color: #00B676;
}

.with_auto{
  width: auto;
  height: 85px;
  /* height: 110px;  */ /* Se puede agregar este estilo para que se vean un poco mejor los inocos, pero queda mas alto.  */
}

.footer {
  position: fixed;
  width: 100%;
  text-align: center;
  bottom: 3vh; /* Ajusta la distancia desde la parte inferior según tus necesidades */
  color: white;
}

